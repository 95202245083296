import React from 'react'
import { Container } from '../components/base'
import { withLayout } from '../components/Layout'
import { Page1 } from '../components/welcome/Page1'
import { Page2 } from '../components/welcome/Page2'
import { Page3 } from '../components/welcome/Page3'

const WelcomeAboardPage = () => {
  return (
    <Container>
      <Page1 />
      <Page2 />
      <Page3 />
    </Container>
  )
}

export default withLayout(WelcomeAboardPage)
