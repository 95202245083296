import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Card, Flex, Text, TextProps } from 'rebass/styled-components'
import { Masthead, TextImage, SeparatorLine, WelcomeTitle } from '../base'

export const fluidImage2 = graphql`
  fragment fluidImage14 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function Page3(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      CharitiesWave: file(relativePath: { eq: "images/CharitiesWave.png" }) {
        ...fluidImage14
      }
    }
  `)
  return (
    <Masthead p={[1, 2, 4]} bg={'primary.text'} css={{ fontFamily: 'Calibre' }}>
      <Box mx={[1, 2, 6]} p={[1, 2, 4]} color={'dark'}>
        <WelcomeTitle color="primary.main" pb={3} fontSize={7} pt={[2, 3, 5]}>
          Your information is held securely.
        </WelcomeTitle>
        <Text fontWeight="bold" pb={3}>
          We use secure systems to manage and store your data to comply with all
          data protection and privacy laws. This information will never be
          shared with anyone outside of permissioned team members in the Choice
          organisation, unless there is a criminal investigation we must comply
          with. We will notify you first if there is any such pending
          occurrence.
        </Text>
        <Text fontWeight="bold" pb={4}>
          Furthermore, we will notify you immediately if we detect any
          suspicious activity.
        </Text>
        <Flex pb={2}>
          <Box width={1 / 8}>
            <TextImage width={[2 / 3, 1 / 2, 1 / 3]}>
              <Img fluid={data.CharitiesWave.childImageSharp.fluid} />
            </TextImage>
          </Box>
          <Box width={1} pb={3} ml={[1, 1, -4]}>
            <WelcomeTitle color="primary.main">Our obligations</WelcomeTitle>
          </Box>
        </Flex>
        <Text fontWeight="bold" pb={4}>
          Choice is a registered Financial Services Provider FSP643289 and a
          member of the FSCL dispute resolution scheme 7719. This means we are a
          regulated institution and if we can’t solve your complaints, you can
          ask for outside arbitration.
        </Text>

        <Flex pb={2}>
          <Box width={1 / 8}>
            <TextImage width={[2 / 3, 1 / 2, 1 / 3]}>
              <Img fluid={data.CharitiesWave.childImageSharp.fluid} />
            </TextImage>
          </Box>
          <Box width={1} pb={3} ml={[1, 1, -4]}>
            <WelcomeTitle color="primary.main">Kia kaha</WelcomeTitle>
          </Box>
        </Flex>

        <Card
          borderColor="secondary.main"
          borderRadius={8}
          borderWidth={2}
          boxShadow="0 2px 16px rgba(0, 0, 0, 0.25)"
          p={3}
        >
          <Text fontWeight="bold">
            Please take a moment to read the Choice Merchant Terms and
            Conditions on{' '}
            <a href="choicetopay.com/merchant-terms">our website </a> to ensure
            you understand how we work and what our obligations are.Thank you
            for working with us and being one of our first merchants. Please
            contact me below if you have any further queries on how we manage
            your information. We look forward to working with you and all of
            Aotearoa to make payments with purpose a reality.
          </Text>
        </Card>
      </Box>
      <SeparatorLine color="primary.main" />
    </Masthead>
  )
}
