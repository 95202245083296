import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Masthead, TextImage, SeparatorLine, WelcomeTitle } from '../base'

export const fluidImage2 = graphql`
  fragment fluidImage13 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function Page2(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      UsingChoiceQRScan: file(
        relativePath: { eq: "images/UsingChoiceQRScan.png" }
      ) {
        ...fluidImage13
      }
    }
  `)
  return (
    <Masthead p={[2, 2, 4]} bg={'primary.dark'} css={{ fontFamily: 'Calibre' }}>
      <Box mx={[1, 2, 6]} p={[1, 2, 4]} color={'primary.text'}>
        <WelcomeTitle pb={3} pt={[2, 3, 5]} color="secondary.main">
          So how does it all work?
        </WelcomeTitle>
        <Text fontWeight="bold" pb={3}>
          Glad you asked! To pay with Choice, users download the Choice app from
          the Apple or Android stores. They are good to go once they sign up and
          link a payment account. To pay in store, they simply scan a QR code
          that shows up on a device at your business. Right now, that’s an
          independent mobile phone with the Choice merchant app. For our next
          release, we will have terminal integrations, which is really exciting
          because this will fit into your reconciliation systems and make life
          that much easier.
        </Text>
        <Text fontWeight="bold" pb={4}>
          We thank you for joining us as an early adopter.
        </Text>
        <Flex>
          <Box width={1 / 8}>
            <TextImage width={[0, 0, 1 / 2]}>
              <Img fluid={data.UsingChoiceQRScan.childImageSharp.fluid} />
            </TextImage>
          </Box>
          <Box width={1} pb={3} ml={-4}>
            <WelcomeTitle color="secondary.main">
              We need to ask you for some company information before we can set
              you up to receive Choice payments.
            </WelcomeTitle>
          </Box>
        </Flex>

        <Text fontWeight="bold" py={3}>
          New Zealand has passed a law called the Anti-Money Laundering and
          Countering Financing of Terrorism Act 2009 (we will call it the
          AML/CFT law). The purpose of the law reflects New Zealand’s commitment
          to the international initiative to counter the impact that criminal
          activity has on people and economies within the global community.
        </Text>
        <Text fontWeight="bold" pb={3}>
          As a payments provider, we must do a number of things to help combat
          money laundering. This is because some people may have bad intentions
          when signing up for and using Choice to accept payments.
        </Text>
        <Text fontWeight="bold" pb={3}>
          To combat this, we are required by law to confirm at least one
          business director’s full name, date of birth, and current address. We
          must take reasonable steps to make sure the information is correct,
          and so we need to ask for documents that show this.
        </Text>
        <Text fontWeight="bold">
          To confirm these details, we must obtain a copy of a document such as
          your driver’s licence to confirm your name, and a document that shows
          your address such as a utility or mobile phone bill.
        </Text>
      </Box>
      <SeparatorLine color="primary.text" />
    </Masthead>
  )
}
