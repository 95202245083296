import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Masthead, Section, SeparatorLine, WelcomeTitle } from '../base'
import { PPPWrapper } from '../index/PPP'

export const fluidImage2 = graphql`
  fragment fluidImage12 on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export function Page1(): React.ReactElement {
  const data = useStaticQuery(graphql`
    query {
      people: file(relativePath: { eq: "images/people.png" }) {
        ...fluidImage12
      }
      planet: file(relativePath: { eq: "images/planet.png" }) {
        ...fluidImage12
      }
      purpose: file(relativePath: { eq: "images/purpose.png" }) {
        ...fluidImage12
      }
    }
  `)
  return (
    <Masthead p={[1, 2, 4]} fontFamily="body">
      <Box mx={[1, 2, 6]} p={[1, 2, 4]} color={'primary.text'}>
        <WelcomeTitle pb={3}>Kia ora, welcome aboard.</WelcomeTitle>
        <Text fontWeight="bold" pb={4} fontSize={[2, 4, 5]}>
          We are stoked to have you here, you are the whole reason we started
          this company. We would love to take the opportunity to explain a bit
          more about how we work, who we are, what information we need to ask
          you for, and how we keep that information secure.
        </Text>
        <WelcomeTitle pb={3}>
          Choice is here to work for our communities.
        </WelcomeTitle>
        <Text fontWeight="bold" pb={4}>
          New Zealand is our home. When we came together to ask ourselves how we
          could make a positive impact on our local communities, we took a
          closer look at some of the systems that underpin our day to day. One
          of these was the payments system. We found out that merchants paid
          $461 million in transaction fees in 2015 alone - that number is
          estimated to be around $750 million in 2019. There are no other option
          for fast, secure payments other than the card network. So Choice was
          born, to create an independent payment platform, for New Zealanders,
          by New Zealanders.
        </Text>
        <WelcomeTitle pb={3}>Our Mahi!</WelcomeTitle>
        <Text fontWeight="bold" pb={3}>
          We spent over 18 months working closely alongside banks, terminal
          providers, and some of the biggest and smallest merchants in Aotearoa
          to understand how our payments system works, and how we could improve
          on it at every step. So we have created a system that uses bank
          payment APIs and QR codes, to create a direct transfer method for
          kiwis who want to pay their favourite businesses. We charge a flat
          rate transaction fee that’s transparent, instead of the confusing
          multitude of fees that you have to deal with. No hidden costs. Just a
          flat rate fee.
        </Text>
        <Text fontWeight="bold">
          We also decided that we would redirect half of our fee to a local
          charity. Our local communities need this more than borderless
          multinationals.
        </Text>
      </Box>
      <Flex justifyContent="center" width={[1, 1, 1]}>
        <Section
          pt={3}
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
          }}
        >
          <PPPWrapper fluid={data.people.childImageSharp.fluid} text="People" />
          <PPPWrapper fluid={data.planet.childImageSharp.fluid} text="Planet" />
          <PPPWrapper
            fluid={data.purpose.childImageSharp.fluid}
            text="Purpose"
          />
        </Section>
      </Flex>
      <SeparatorLine color="primary.text" />
    </Masthead>
  )
}
